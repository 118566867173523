<template>
  <div class="home" ref="home">
    <div id="video">
      <video autoplay loop muted>
        <source src="https://musician-release-1305250541.file.myqcloud.com/web/static/background-video.mp4" type="video/mp4" >
      </video>
    </div>

    <div class="header headerbg" v-if="release">
      <navbar style="background: transparent" />
      <!--div class="navs">
        <logo class="left"></logo>
        <div class="right">
          <qrcode></qrcode>
          <div class="btn" v-else @click="$router.replace('/login')">登录/注册</div>

        </div>
      </div-->
      <div class="title"><img src="../assets/title.png" alt=""></div>
      <div class="desc">70家合作厂牌 数万名合作音乐人 发行30万首歌曲</div>
      <div style="display: flex; justify-content: center;">
        <div class="publish" @click="topublish(1)" v-show="hasPermission(6)"><img src="../assets/HomeSong.png"> 发布歌曲</div>
        <div class="publish" @click="topublish(2)" v-show="hasPermission(7)"><img src="../assets/HomeDome.png"> 出售词曲</div>
      </div>
    </div>
    <div class="header check" v-else>
      <navbar style="background: transparent" />
      <!--div class="navs">
        <logo class="left"></logo>
        <div class="right">
          <qrcode></qrcode>

          <div class="btn" v-else @click="$router.replace('/login')">登录/注册</div>

        </div>
      </div-->
      <div id="titlenav" class="title"><img src="../assets/title.png" alt=""></div>
      <div class="desc">选择身份 开启创作之旅</div>
      <!-- <div class="publish" @click="$router.replace('/publish')">发布作品</div> -->
      <!-- 已登录位认证厂牌 -->

      <div class="box" v-if="loginflag==1">
        <div class="alterbox">
          <div>入驻音乐人</div>
          <div><img src="https://musician-release-1305250541.file.myqcloud.com/web/static/img/musician.png" alt="" /></div>
          <div><button @click="Settled(0)">立即入驻</button></div>
        </div>

        <div class="alterbox">
          <div>入驻厂牌/机构</div>
          <div><img src="https://musician-release-1305250541.file.myqcloud.com/web/static/img/brand.png" alt="" /></div>
          <div><button @click="Settled(1)">立即入驻'</button></div>
        </div>
      </div>
      <!--未注册 -->
      <div class="box" v-if="loginflag==0">
        <div class="register">
          <div><img src="https://musician-release-1305250541.file.myqcloud.com/web/static/img/musician.png" alt="" /></div>
          <div>音乐人</div>
          <div>
            <p>我是歌手/词曲作者</p>
            <p>我要发布歌曲，出售词曲作品</p>
          </div>
          <div><button @click="loginbut(0)">立即入驻</button></div>
        </div>
        <div class="register">
          <div><img src="https://musician-release-1305250541.file.myqcloud.com/web/static/img/brand.png" alt="" /></div>
          <div>厂牌/机构</div>
          <div>
            <p>我是厂牌/机构</p>
            <p>我要发布歌曲，出售词曲作品</p>
          </div>
          <div><button @click="loginbut(1)" >立即入驻</button></div>
        </div>
      </div>
      <!--登录  -->
      <div class="mask" @click="cancelMask" v-if="loginflag==2">
        <div class="box" >
          <div class="alterbox">
            <div>我是音乐人</div>
            <div><img src="https://musician-release-1305250541.file.myqcloud.com/web/static/img/musician.png" alt="" /></div>
            <div><button @click="loginbut(0)">登录</button></div>
          </div>
          <div class="alterbox">
            <div>我是厂牌/机构</div>
            <div><img src="https://musician-release-1305250541.file.myqcloud.com/web/static/img/brand.png" alt="" /></div>
            <div><button @click="loginbut(1)">登录</button></div>
          </div>
        </div>
      </div>

    </div>
    <div class="intro">
      <div class="title">海葵分贝平台能为您做些什么</div>
      <div class="items">
        <div class="item">
          <div class="iconwrap">
            <img src="https://musician-release-1305250541.file.myqcloud.com/web/static/img/intro1.png" class="icon" />
          </div>
          <div class="name">版税提现</div>
          <div class="desc">版税收益随时提现，安全又快捷</div>
        </div>
        <div class="item">
          <div class="iconwrap">
            <img src="https://musician-release-1305250541.file.myqcloud.com/web/static/img/intro2.png" class="icon" />
          </div>
          <div class="name">侵权监测</div>
          <div class="desc">智能侵权监测，为您争取维权收益</div>
        </div>
        <div class="item">
          <div class="iconwrap">
            <img src="https://musician-release-1305250541.file.myqcloud.com/web/static/img/intro3.png" class="icon" />
          </div>
          <div class="name">全网发行</div>
          <div class="desc">
            让海葵帮助您的音乐被更多人听到，让你的作品创造更多收益。
          </div>
        </div>
      </div>
    </div>
    <div class="users">
      <div class="title">他们都在用</div>
      <div class="items">
        <div class="item up">
          <img src="https://musician-release-1305250541.file.myqcloud.com/web/static/img/user1.png" class="head" />
        </div>
        <div class="item content">
          <div class="name">胡66</div>
          <div class="works">代表作:《后来遇见他》《浪人琵琶》</div>
          <div class="desc">内地知名女歌手，2018年单曲酷狗TOP500霸榜72周。</div>
        </div>
        <div class="item up">
          <img src="https://musician-release-1305250541.file.myqcloud.com/web/static/img/user2.png" class="head" />
        </div>
        <div class="item content">
          <div class="name">王靖雯</div>
          <div class="works">代表作:《爱，存在》《不知所措》</div>
          <div class="desc">
            内地知名女歌手，中央电视台《星光大道》周冠军，歌曲《爱，存在》全网总播放量已突破4亿。
          </div>
        </div>
        <div class="item down">
          <img src="https://musician-release-1305250541.file.myqcloud.com/web/static/img/user3.png" class="head" />
        </div>
        <div class="item content">
          <div class="name">丁芙妮</div>
          <div class="works">代表作:《只是太爱你》《遥远的你》</div>
          <div class="desc">
            唱作型女歌手，不靠谱组合成员，2015《一唱成名》全国总冠军。
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="links">
        <div class="item" @click="goPrivacy">隐私政策</div>
        <div class="item" @click="goAgreement">海葵分贝服务条款</div>
        <a class="item flex-center" href="mailto:artist@hikoon.com">
          <img src="../assets/email.png" class="icon" />
          artist@hikoon.com
        </a>
      </div>
      <copyright />
    </div>
    <!-- 登录弹窗 -->
    <Login ref=alterlogn :routerstate="routerstate"></Login>
  </div>
</template>
<style lang="scss" scoped>
@import '../styles/initialization.scss';

@media only screen and (max-width:1400px) {
  #titlenav {
    padding-top: 100px;
  }
}
.home{
  min-width: 1200px;
}
#video{
  overflow: hidden;
  z-index: -1;
  position: absolute;
  top: 0;
  width: 100%;
  min-width: 1200px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0E0E0E;
  video{
    position: absolute;
    width:100%;
    height: 100%;
    object-fit:cover;
  }
}
.mask{
  top:0;
  left:0;
  right:0;
  bottom:0;
  position:absolute;
  z-index: 10;
  background-color:rgba(0,0,0,.4);
  padding-top:300px;
  height:2162px;
}
.check {
  div.title {
    padding-top: 130px;
  }
  .box {
    margin-top: 34px;
    display: flex;
    justify-content: center;
    .register {
      width: 340px;
      height: 370px;
      background: #ffffff;
      margin-left: 43px;
      border-radius: 6px;
      img{
        width:104px;
        height:104px;
      }
      div:nth-child(1) {
        margin-top: 56px;
      }
      div:nth-child(2) {
        margin-top: 7px;
        height: 42px;
        font-size: 30px;
        font-family:PingFang SC;
        font-weight: 600;
        color: #222222;
        line-height: 42px;
      }
      div:nth-child(3) {
        margin-top: 18px;
        height: 44px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #aaaaaa;
        line-height: 22px;
      }
      div:nth-child(4) {
        margin-top: 18px;
        button {
          width: 116px;
          height: 44px;
          background: #ff004d;
          border: none;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 800;
          color: #ffffff;
          line-height: 21px;
          cursor: pointer;
          border-radius: 2px;
        }
      }
    }
    .alterbox {
      width: 340px;
      height: 370px;
      background: #ffffff;
      margin-left: 43px;
      border-radius: 6px;
      overflow: hidden;
      img{
        width:104px;
        height:104px;
      }
      div:nth-child(1) {
        margin-top: 48px;
        height: 28px;
        font-size: 20px;
        font-family:PingFang SC;
        font-weight: 600;
        color: #222222;
        line-height: 28px;
      }
      div:nth-child(2) {
        margin-top: 40px;
        margin-bottom: 42px;
      }
      div {
        button {
          cursor: pointer;
          width: 116px;
          height: 44px;
          background: #ff004d;
          border: none;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 800;
          color: #ffffff;
          line-height: 21px;
          border-radius: 2px;
        }
      }
    }
  }
}
.header {
  height: 100vh;
  background-position: center;
  background-size: auto 780px;
  text-align: center;
  /*
  .navs{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width:1200px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    .left{
       flex: 1;
    }
    .right{
      display: flex;
      .btn{
        width: 80px;
        height: 26px;
        background: $base-color;
        border-radius: 20px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 19px;
        cursor: pointer;
      }
    }
  }
  */
  .title {
    padding-top: 300px;
    font-size: 50px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 60px;
    img{
      width: 753px;
      height: 52px;
    }
  }
  .desc {
    margin-top: 16px;
    height: 28px;
    font-size: 20px;
    font-family: PingFang SC-Light;
    font-weight: 300;
    color: #ffffff;
    line-height: 28px;
  }
  .publish {
    cursor: pointer;
    margin:{
      top: 32px;
      left: 27.5px;
      right: 27.5px;
    };
    width: 149px;
    height: 53px;
    border: 1px solid rgba(255,255,255,.6);
    border-radius: 2px;
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Semibold;
    font-weight: 600;
    color: #ffffff;
    letter-spacing: 0.02px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 32px;
      height: 32px;
      margin-right: 4px;
    }
  }
}
.intro {
  height: 703px;
  background: url(https://musician-release-1305250541.file.myqcloud.com/web/static/img/introbg.png) no-repeat;
  background-position: center;
  background-size: auto 703px;
  text-align: center;
  position: relative;
  z-index: 2;
  .title {
    padding-top: 80px;
    height: 60px;
    font-size: 43px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 60px;

  }
  .items {
    margin-top: 79px;
    display: flex;
    justify-content: center;
    .item {
      width: 200px;
      margin-right: 175px;
      text-align: center;
      &:last-child {
        margin-right: 0;
      }
      .icon {
        width: 200px;
        height: 160px;
      }
      .name {
        margin-top: 9px;
        height: 60px;
        font-size: 26px;
        font-family: PingFang SC-Semibold;
        font-weight: 600;
        color: #ffffff;
        line-height: 60px;
      }
      .desc {
        margin: auto;
        margin-top: 3px;
        width: 170px;
        font-size: 16px;
        font-family: PingFang SC-Light;
        font-weight: 300;
        color: #ffffff;
        line-height: 22px;
      }
    }
  }
}
.users {
  margin-top: -190px;
  height: 1026px;
  background: url(https://musician-release-1305250541.file.myqcloud.com/web/static/img/usersbg.png) no-repeat;
  background-position: center;
  background-size: auto 1026px;
  text-align: center;
  .title {
    padding-top: 190px;
    height: 60px;
    font-size: 43px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 60px;
  }
  .items {
    margin: auto;
    margin-top: 50px;
    width: 900px;
    height: 600px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
    .item {
      width: 300px;
      height: 300px;
      //   background: #ca003c;
      background-repeat: no-repeat;
      background-size:100% 100%;
    }
    .item:nth-of-type(2){
      background-image:url(https://musician-release-1305250541.file.myqcloud.com/web/static/img/mask1.png);

    }
    .item:nth-of-type(4){
      background-image:url(https://musician-release-1305250541.file.myqcloud.com/web/static/img/mask2.png);

    }
    .item:nth-of-type(6){
      background-image:url(https://musician-release-1305250541.file.myqcloud.com/web/static/img/mask3.png);

    }
    .head {
      width: 300px;
      height: 300px;
    }
    .content {
      padding: 40px 25px;
      box-sizing: border-box;
      text-align: left;
      .name {
        height: 42px;
        font-size: 30px;
        font-family: PingFang SC-Semibold;
        font-weight: 600;
        color: #ffffff;
        line-height: 42px;
      }
      .works {
        margin-top: 10px;
        height: 22px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 22px;
        white-space: nowrap;
        /* overflow: hidden; */
      }
      .desc {
        margin-top: 10px;
        height: 44px;
        font-size: 16px;
        font-family: PingFang SC-Light;
        font-weight: 300;
        color: #ffffff;
        line-height: 22px;
      }
    }
    .up {
      position: relative;
      //   &::after {
      //     content: '';
      //     width: 0;
      //     height: 0;
      //     border: 10px solid transparent;
      //     border-bottom-color: #ca003c;
      //     position: absolute;
      //     left: 50%;
      //     bottom: 0;
      //   }
    }
    .down {
      position: relative;
      //   &::after {
      //     content: '';
      //     width: 0;
      //     height: 0;
      //     border: 10px solid transparent;
      //     border-top-color: #ca003c;
      //     position: absolute;
      //     left: 50%;
      //     top: 0;
      //   }
    }
  }
}
.footer {
  height: 405px;
  background: url(https://musician-release-1305250541.file.myqcloud.com/web/static/img/footerbg.png) no-repeat;
  background-position: center;
  background-size: auto 405px;
  text-align: center;
  position: relative;
  margin-top: -240px;
  .links {
    padding-top: 272px;
    display: flex;
    justify-content: center;
    .item {
      cursor: pointer;
      padding: 0 25px;
      height: 17px;
      font-size: 12px;
      font-family:PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 17px;
      position: relative;
      text-decoration: none;
      &::before {
        content: '';
        width: 1px;
        height: 8px;
        background: #d8d8d8;
        opacity: 0.5;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      .icon {
        width: 14px;
        height: 14px;
        margin-right: 7px;
      }
      &:last-child::before {
        display: none;
      }
    }
  }
  .copyright {
    margin-top: 25px;
    height: 14px;
    font-size: 10px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 14px;
    opacity: 0.5;
  }
}
</style>

<script>
// import logo from '@/components/Logo.vue'
// import qrcode from '@/components/Qrcode.vue'
import navbar from '@/components/Navbar.vue'
import copyright from '@/components/Copyright.vue'
import { goPrivacy, goAgreement } from '@/utils/utils.js'
import Login from './Login.vue'
export default {
  data() {
    return {
      // 判断入驻方式
      routerstate:0,
      //判断认账入驻
      atcn:0,
      // 监听数据
    }
  },
  name: 'Home',
  components: {
    navbar,
    copyright,
    Login
  },

  computed: {
    loginflag(){
      return this.$store.state.loginflag
    },
    user(){
      return this.$store.state.usermsg
    },
    release(){
      return this.$store.state.release
    },
    permissionIds(){
      return this.$store.state.roleInfo && this.$store.state.roleInfo.subIds ? this.$store.state.roleInfo.subIds : []
    },
    isCompany(){
      return this.$store.state.usermsg.webLoginType === 1
    },
    isAdmin(){
      return this.$store.state.usermsg.userCompanyVo.managerUser === 1
    }

  },
  created() {
    if(this.$store.state.user !==null ){
      this.$store.state.release=true;
      if(this.$route.query.flag){
        this.$store.state.release=true;
      }
      this.$post('/api/user/userMsg').then((res=>{
        if(res.data.userCompanyVo==null&&res.data.verifiedStatus==0){
          this.$store.commit('logntype',1)
          this.$store.state.release=false;
          this.$store.commit('UMSG',res.data)
        }
      }))
      if(this.$store.state.hiddestate == 0&&this.$store.state.check){
        this.$store.commit('logntype',1)
        this.$store.state.release=false;
        this.$store.state.check=false
      }
    }
    // this.$axios.get("https://app.if1f.com");
  },
//   获取home标签高度
  methods: {
    goPrivacy,
    goAgreement,
    hasPermission(id) {
      return !this.isCompany || this.isAdmin || this.permissionIds.find(e => typeof id === 'object' ? id.find(f => e === f.permissionId) : e === id) || (this.user.webLoginType === 1 && this.user?.userCompanyVo.status === 0)
    },
    loginbut(val){
      this.$refs.alterlogn.dialogVisible=true;
      this.routerstate=val;
      this.atcn=val;
      localStorage.setItem("release",val)
    },
    // 入驻
    Settled(val){
      localStorage.setItem('release',val);
      if(val==1){
        this.$router.push("/certification/certificationFrom")
      }else{
        this.$router.push("/certify")
      }
    },
    // 发布作品
    topublish(val){
      // 机构认证登录状态
      let status="";
      // 音乐人登录状态
      let state="";
      this.$post('/api/user/userMsg').then((res=>{
        this.$store.commit('UMSG',res.data)
        if(res.data.userCompanyVo !=null){
          status=res.data.userCompanyVo.status;
        }
        state=res.data.verifiedStatus
        localStorage.getItem("release")
        if(res.data.webLoginType==1){
          if(status==0){
            this.$router.push("/certification/examine")
          }else if(
              status==2
          ){
            this.$router.push("/certification/examine")
          }else{
            if(val === 1){
              if(res.data.userCompanyVo.companyType === 1){
                this.$router.push("/issue")
              }else {
                this.$router.push("/release/0")
              }
            }else {
              this.$router.push("/demo/0")
            }
          }
        }else{
          if(state==0){
            this.$router.push("/certify");
          } else if(state==3){
            if(val === 1){
              this.$router.push("/release/0")
            }else {
              this.$router.push("/demo/0")
            }
          } else if(
              state==1||state==2
          ){
            this.$router.push("/Musiciancon")
          }
        }
      }))

    },
    cancelMask(){
      this.$store.commit('logntype',0)
    }
  },



}
</script>
