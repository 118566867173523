<template>
  <div class="login">
    <!-- <navbar :login="true" />  -->
    <div class="main">
      <el-dialog
        type="primary"
        center
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="690px"
      >
        <el-menu
          active-text-color="#ff004d"
          slot="title"
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="1">手机</el-menu-item>
          <el-menu-item index="2">扫码</el-menu-item>
          <el-menu-item index="3">账密</el-menu-item>
        </el-menu>
        <div class="login-form">
          <div class="form" v-if="!isQrcode">
            <template v-if="loginType == 'mobile'">
              <div class="form-item form-tips">
                <div class="tips">未注册的手机号验证后自动创建帐号</div>
                <div style="display: flex;justify-content: space-between;">
                     <el-select v-model="region"  @change="selectChange" :popper-append-to-body="true" >
                            <el-option class="option" style="width:230px;"
                            v-for="(item,index) in options"
                            :key="index"
                            :value="'+'+item.tel"
                            >
                            {{item.name}}({{'+'+item.tel}})
                            </el-option>
                        </el-select>
                    <input
                  class="input phoneStyle"
                  maxlength="11"
                  placeholder="请输入手机号码"
                  v-model="mobile"
                /></div>

              </div>
              <div class="form-item">
                <input
                  class="input"
                  placeholder="请输入验证码"
                  v-model="code"
                />
                <a
                  href="javascript:;"
                  class="send-code"
                  :class="{ disabled: !canSend }"
                  @click="sendCode"
                >
                  {{ timeLeft == 60 ? '获取验证码' : `剩余${timeLeft}秒` }}
                </a>
              </div>
            </template>
            <template v-else>
              <div class="form-item">
                <input
                  class="input"
                  placeholder="账号/邮箱号"
                  v-model="account"
                />
              </div>
              <div class="form-item">
                <input
                  class="input"
                  placeholder="密码"
                  type="password"
                  v-model="pass"
                />
              </div>
            </template>

            <div class="form-action">
              <el-button class="submit" :loading="submitbol" @click="login">登录</el-button>
            </div>

            <div class="agreement" @click="agreed = !agreed">
              <img v-if="agreed" src="../assets/checked.png" class="icon" />
              <img v-else src="../assets/check.png" class="icon" />
              <div class="text">
                同意<span @click.stop.prevent="goPrivacy">《隐私政策》</span
                >及<span @click.stop.prevent="goAgreement"
                  >《海葵分贝服务条款》</span
                >
              </div>
            </div>
          </div>

          <div class="qrcode" v-if="isQrcode">
            <div class="title">
              <p v-if="isQrcode">手机APP扫码登录</p>
              <p v-if="isQrcode">打开海葵分贝，我的-扫一扫</p>
            </div>
            <img
              :src="qrcode || ''"
              :class="{ blur: isQrcodeExpireTime }"
              alt="qrcode"
            />
            <div
              v-if="isQrcodeExpireTime"
              class="qrcode-cover"
              @click="getQrcode"
            >
              二维码失效, 点击重新获取<br />
              <img
                src="../assets/refresh.png"
                alt="refresh"
                width="26"
                height="28"
                style="margin-top: 14px"
              />
            </div>
          </div>

          <!-- <div class="other">
                    <div class="title">其他登录方式</div>
                    <div class="thirds">
                        <div class="item" v-if="isQrcode || loginType != 'mobile'" @click="isQrcode = false; loginType='mobile'">
                            <img src="../assets/third-mobile.png" class="icon" />
                        </div>
                        <div class="item" v-if="isQrcode || loginType != 'account'" @click="isQrcode = false; loginType='account'">
                            <img src="../assets/third-user.png" class="icon" />
                        </div>
                    </div>
                </div> -->

          <!-- <div class="corner" @click="isQrcode = !isQrcode">
                    <img v-if="isQrcode" src="../assets/login-user.png" class="icon" />
                    <img v-else src="../assets/login-qrcode.png" class="icon" />
                </div> -->
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.main {
//   height: calc(100vh - 56px);
  display: flex;
  align-items: center;
  justify-content: center;
//   background: url(../assets/loginbg.png) no-repeat;
  background-size: cover;
  // 弹窗
  ::v-deep .el-dialog {
    box-sizing: border-box;
    border-radius: 6px;
    overflow: hidden;
  }
  ::v-deep .el-dialog__header {
    padding: 0px;
    text-align: center;
    .el-menu {
      display: flex;
      justify-content: center;
      .el-menu-item {
        margin: 0px 34px;
        padding: 0px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
      }
    }
  }
  ::v-deep .el-dialog__body {
    padding: 0px;
  }

  .box {
    margin-top: 34px;
    display: flex;
    justify-content: center;
    .alterbox {
      text-align: center;
      width: 340px;
      height: 370px;
      background: #ffffff;
      margin-left: 43px;

      div:nth-child(1) {
        margin-top: 48px;
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #222222;
        line-height: 28px;
      }
      div:nth-child(2) {
        margin-top: 40px;
        margin-bottom: 42px;
      }
      div {
        button {
            cursor: pointer;
          width: 116px;
          height: 44px;
          background: #ff004d;
          border: none;
          font-size: 15px;
          font-family: PingFangSC-Heavy, PingFang SC;
          font-weight: 800;
          color: #ffffff;
          line-height: 21px;
        }
      }
    }
  }
  .login-form {
    width: 450px;
    height: 380px;
    margin: 0 auto;
    background: #ffffff;
    opacity: 0.9;
    padding: 0px 42px 0;
    // box-sizing: border-box;
    text-align: left;
    position: relative;
    display: flex;
    flex-direction: column;

    .form {
      margin-top: 70px;
      flex: 1;
    }
    .form-item {
      position: relative;
      padding: 10px 0;
      .input {
        width:450px;
        height: 42px;
        background: #ffffff;
        border: 1px solid #e2e6e7;
        padding-left: 12px;
        border-radius: 2px;
        box-sizing: border-box;
        outline: none;
        display: flex;
        align-items: center;
        &::-webkit-input-placeholder {
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #555555;
          line-height: 21px;
        }
      }
      .send-code {
        width: 80px;
        height: 36px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        border-radius: 2px;
        background: $base-color;
        color: #ffffff;
        line-height: 17px;
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: 13px;
        right: 3px;
        text-decoration: none;
        &.disabled {
          color: #666666;
          background: #f0f4f5;
        }
      }
    }
    .form-tips {
      padding-bottom: 0;
      padding-left: 2px;
      margin-bottom:24px;
      ::v-deep .el-input__inner {
          border: 1px solid #dedede;
          padding-left: 12px;
         height: 42px;
         box-sizing: border-box;
        border-radius: 2px;
      }

      .tips {
        margin-bottom: 18px;
        height: 14px;
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 14px;
      }
      .phoneStyle{
          width: 314px;
          display: inline-block;
      }
      .el-select{
          display: inline-block;
           width:120px;
          box-sizing: border-box;
          .option{
            width: 230px;
            font-size: 15px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: LEFT;
            color:#666666;
            letter-spacing: 0px;
}
      }
    }

    .form-action {
      margin-top: 14px;
      .submit {
        width: 450px;
        height: 42px;
        border-radius: 2px;
        background: $base-color;
        border: none;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 21px;

        /*  display: flex;
                align-items: center;
                justify-content: center; */
      }
    }
    .qrcode {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-flow: column;
      > img {
        width: 172px;
        height: 172px;
        display: block;
      }
      > .title {
        margin-bottom: 22px;
        p {
          margin: 0px;
        }
        p:nth-child(1) {
          text-align: center;
          height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #222222;
          line-height: 22px;
        }
        p:nth-child(2) {
          text-align: center;
          margin-top: 4px;
          height: 17px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 17px;
        }
      }
    }
    .agreement {
      margin-top: 10px;
      display: flex;
      height: 14px;
      font-size: 10px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      line-height: 14px;
      .icon {
        width: 14px;
        height: 14px;
        margin-right: 6px;
      }
      .text {
        span {
          cursor: pointer;
        }
      }
    }
    .other {
      height: 105px;
      .title {
        /* margin-top: 29px; */
        height: 21px;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        line-height: 21px;
      }
      .thirds {
        margin-top: 14px;
        padding-bottom: 40px;
        display: flex;
        .item {
          width: 40px;
          height: 40px;
          margin-right: 19px;
          .icon {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
      }
    }

    .corner {
      width: 52px;
      height: 52px;
      position: absolute;
      right: 5px;
      top: 5px;
      .icon {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
}
.blur {
  filter: blur(5px);
}
.qrcode-cover {
  position: absolute;
  height: 115px;
  width: 172px;
  background-color: rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 12px;
  color: white;
  padding-top: 57px;
}
</style>
<script>
import md5 from 'md5'
import { goPrivacy, goAgreement, getUid } from '@/utils/utils.js'
import * as CONST from '@/utils/const.js'
import request from '@/utils/request'
// import toast from '@/utils/toast.js'
import countryPhone from '@/utils/countryPhone.js'
export default {
 props:['routerstate'],
  data() {
    return {
      mobileRegExp: /\d{7,11}$/, // 手机号校验正则
      isQrcode: false, // 是否扫码登录
      loginType: 'mobile', // mobile 手机验证码登录，account 账号密码登录
      agreed: true, // 是否勾选了同意协议
      mobile: '',
      code: '',
      account: '',
      pass: '',
      timeLeft: 60,
      interval: null, // 倒计时
      qrcode: null,
      serverRandom: null, // 二维码识别码
      qrcodeExpireTime: 0,
      isQrcodeExpireTime: false,
      activeIndex: '1', //选项卡切换
      dialogVisible: false, //弹框
      smsMessage:true,
      options: countryPhone,
      region:'+86',
      regionstr:'86',
      submitbol:false,
    }
  },
  computed: {
    canSend() {
      return this.timeLeft == 60 && this.mobileRegExp.test(this.mobile)
    }
  },
  watch: {
    isQrcode(val) {
      if (val) {
        this.getQrcode()
      }
    }
  },
  methods: {
      selectChange(val) {
            //字符串截取获得区号
        this.regionstr=val.substring(1,val.length)
      },
    goPrivacy,
    goAgreement,
    sendCode() {
      if (this.timeLeft < 60||!this.smsMessage) {
        return false
      }
      if(!this.canSend) {
         return
      }
      this.smsMessage=false;
      this.$post('/api/sms/send', {
        zone: this.regionstr,
        phone: this.mobile,
        type: 2,
        cs: parseInt(Date.now() / 1000)
      }).then((res) => {
        this.smsMessage=true;
        console.log('发送验证码结果', res)
        // 开始倒计时
        this.countDown()
      })
    },
    // 开始倒计时
    countDown() {
      this.timeLeft--
      if (this.interval) {
        this.clearInterval()
      }
      this.interval = setInterval(() => {
        this.timeLeft--
        if (this.timeLeft <= 0) {
          this.clearInterval()
          this.timeLeft = 60
        }
      }, 1000)
    },
    // 取消倒计时
    clearInterval() {
      clearInterval(this.interval)
      this.interval = null
    },
    // 统一登录方法
    login() {
      if (!this.agreed) {
        return this.$toast('请阅读并同意服务条款')
      }
      let method =
        'loginBy' +
        this.loginType.charAt(0).toUpperCase() +
        this.loginType.slice(1)
      if (!this[method]) {
        return this.$toast('暂不支持此登录方式')
      }
      return this[method]()
    },
    // 使用账号密码登录
    async loginByAccount() {
      if (this.account == '' || this.pass == '') {
        return this.$toast('请输入账号密码')
      }
      this.submitbol=true;
      this.$post('/user/login', {
        appVer: CONST.VERSION,
        devCode: CONST.DEV_CODE,
        devName: navigator.userAgent,
        endpointType: CONST.ENDPOINT_TYPE,
        os: CONST.OS_CODE,
        osVer: 'account',
        phone: this.account,
        pushId: '',
        pwd: md5(this.pass)
      }).then(this.loginCallback)
    },
    // 使用手机号和验证码登录
    loginByMobile() {
      if (!this.mobileRegExp.test(this.mobile) || this.code == '') {
        return this.$toast('请输入手机号和验证码')
      }
       this.submitbol=true;
      this.$post('/user/loginBySms', {
        acode: this.code,
        appVer: CONST.VERSION,
        devCode: CONST.DEV_CODE, //  navigator.userAgent
        devName: navigator.userAgent,
        os: CONST.OS_CODE,
        osVer: 'mp',
        phone: this.mobile,
        zone:this.regionstr
        // "pid"       : '',
        // "pushId": ""
      }).then(this.loginCallback)
    },
    loginCallback({ data }) {
         this.submitbol=false;
      this.$store.commit('LOGIN', {
        secret: data.secret,
        session: data.session,
        user: data.user
      })
      // 未认证跳转到认证页面
    //   let status = data.user.verifiedStatus
      let url;
    // 个人跳转
      if(this.routerstate==0){
        // 判端当前身份
        if(this.$store.state.user.webLoginType!=0 && this.$store.state.user.webLoginType!=null){
           this.$post('/api/company/identityChange',{id:this.$store.state.user.webOrgId,type:0}).then((res)=>{
                let data=res.data
                // console.log(data,"2")
                if(res.code==0){
                  this.$store.commit('LOGIN', {
                    secret: data.secret,
                    session: data.session,
                    user: data.user
              })
                  this.$post('/api/user/userMsg').then((res)=>{
                    console.log(res.data, 'res.data');
                    this.$store.commit('hiddenBut',res.data)
                    this.$store.commit('UMSG',res.data)
                  })
                 }

          url = '/certify'
          if(data.user.verifiedStatus==1||data.user.verifiedStatus==2){
              url="/musiciancon"
          }
          if(data.user.verifiedStatus==3){
              url='/create'
          }
           this.$router.replace(url)
            })
        }else{
            url = '/certify'
          if(data.user.verifiedStatus==1||data.user.verifiedStatus==2){
              url="/musiciancon"
          }
          if(data.user.verifiedStatus==3){
              url='/create'
          }
         this.$router.replace(url)
        }
      }

      //机构认证跳转
      if(this.routerstate==1){
        //   登陆时切换账号
          if(this.$store.state.user.webLoginType!=1 && this.$store.state.user.webLoginType!=null&&this.$store.state.user.userCompanyVo!=null){

               this.$post('/api/company/identityChange',{id:this.$store.state.user.userCompanyVo.id,type:1}).then((res)=>{
                let data=res.data
                if(res.code==0){
                  this.$store.commit('LOGIN', {
                    secret: data.secret,
                    session: data.session,
                    user: data.user
                })
                  this.$post('/api/user/userMsg').then((res)=>{
                    console.log(res.data, 'res.data');
                    this.$store.commit('hiddenBut',res.data)
                    this.$store.commit('UMSG',res.data)
                  })
            }
            url='/certification/certificationFrom'
           if(this.$store.state.user.userCompanyVo !=null){
                if(data.user.userCompanyVo.status==0||data.user.userCompanyVo.status==2){
                url="/certification/examine"
                }
            if (data.user.userCompanyVo.status == 1) {
                url = '/create'
                }
           }

             this.$router.replace(url)
                    })
          } else {
              url='/certification'
              if(this.$store.state.user.userCompanyVo !=null){
            if(data.user.userCompanyVo.status==0||data.user.userCompanyVo.status==2){
                url="/certification/examine"
                }
            if (data.user.userCompanyVo.status == 1) {
                url = '/create'
                }
              }

             this.$router.replace(url)
          }

      }
    },
    // 获取扫码登录二维码
    async getQrcode() {
      this.isQrcodeExpireTime = false
      let uid = getUid()
      let ua = encodeURIComponent(navigator.userAgent)
      let res = await this.$get(
        `/api/qrcode/getQrCode?uuid=${uid}&browser=${ua}`
      )
      this.qrcode = res.imgBase64
      this.qrcodeExpireTime = Date.now() + res.timeout * 1000
      this.serverRandom = res.serverRandom
      this.queryQrcode()
    },
    // 轮询二维码登录状态
    queryQrcode() {
      setTimeout(async () => {
        if (!this.isQrcode) return
        if (Date.now() > this.qrcodeExpireTime) {
          this.isQrcodeExpireTime = true
          return
        }

        let res = await request.post('/api/qrcode/qrCodeCheckByWeb', {
          appVer: CONST.VERSION,
          devCode: CONST.DEV_CODE,
          devName: navigator.userAgent,
          os: CONST.OS_CODE,
          osVer: 'qrcode',
          serverRandom: this.serverRandom
        })
        if (res.code === 0) {
          return this.loginCallback(res)
        }
        console.log('轮询结果', res)
        this.queryQrcode()
      }, 5000)
    },
    // 选项卡切换
    handleSelect(key) {
      // mobile 手机验证码登录，account 账号密码登录
      if (key == 1) {
        this.isQrcode = false
        this.loginType = 'mobile'
      } else if (key == 2) {
        this.isQrcode = true
      } else if (key == 3) {
        this.loginType = 'account'
        this.isQrcode = false
      }
    },
  },
}
</script>
